import { Container, Row, Col, Image, Button, Nav, Navbar } from 'react-bootstrap';

import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAuth } from '../auth/authThunks';
import { useNavigate } from 'react-router';

export function Header(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const isActivePage = (page, currentpage) => {
        if (page === currentpage) return 'active';

        return '';
    }

    
    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" fixed="top">
                <Container fluid>
                    <Navbar.Brand href="/">
                        <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
                            <Image src={require('../../assets/images/logo.png')} />
                        </a>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    
                    </Nav>
                    <Nav>
                        <Nav.Link className={isActivePage('devices', props.page)} onClick={() => navigate("/devices")}>Devices</Nav.Link>
                        <Nav.Link className={isActivePage('profile', props.page)} onClick={() => navigate("/profile")}>Profile</Nav.Link>
                        <Nav.Link href="#" onClick={() => dispatch(logoutAuth()) }>Logout <Icon.BoxArrowRight /></Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}