import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ACCESS_TOKEN, SESSION_TOKEN } from '../utils/tools';
import useLocalStorage from '../hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_EVENTS, selectAuth } from '../features/auth/authSlice';
import { getCurrentUser } from '../features/auth/authThunks';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { Splash } from '../pages/Splash';
import { Col, Container, Image, Row } from 'react-bootstrap';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
    
    const toastId = useRef();
    const { status, event, message, data } = useSelector(selectAuth);
    const dispatch = useDispatch();
    const [storedValue, setStoredValue] = useLocalStorage(SESSION_TOKEN, null);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem(SESSION_TOKEN) ?? null));

    useEffect(() => {
        // * ifninit => getCurrentUser
        if (status === "initial") {
            dispatch(getCurrentUser());
        }

        // * Act as refreshToken
        if (event === AUTH_EVENTS.current_user) {
            if (status === "success") {
                setUser(data)
                setStoredValue(JSON.stringify(data));
                window.localStorage.setItem(ACCESS_TOKEN, data?.token)
            }

            if (status === "error") {
                setUser(null)
                setStoredValue(null);
            }
        }


        if (event === AUTH_EVENTS.login) {
            if (status === "loading") {
                toast.info("Please wait loading", {
                    autoClose: 1000,
                })
            }

            if (status === "error") {
                toast.dismiss();
                toast.error(message, {
                    autoClose: 2000
                })
            }

            if (status === "loaded") {
                toast.dismiss();
                toast.success(message, {
                    autoClose: 2000,
                })

                window.localStorage.setItem(ACCESS_TOKEN, data?.token)
                setUser(data)
                setStoredValue(JSON.stringify(data));

            }
        }

        if (event === AUTH_EVENTS.update) {
            if (status === "error") {
                toast.dismiss();
                toast.error(message, {
                    isLoading: false,
                    autoClose: 2000
                })
            }

            if (status === "loaded") {
                toast.dismiss();
                window.localStorage.setItem(ACCESS_TOKEN, data?.token)
                toast.success(message, {
                    autoClose: 2000,
                    closeOnClick: true,
                    onClose: () => dispatch(getCurrentUser()),
                })
            }
        }

        // if (event === AUTH_EVENTS.register) {
        //     if (status === "error") {
        //         toast.error(message, {
        //             isLoading: false,
        //             autoClose: 2000
        //         })
        //     }

        //     if (status === "loaded") {
        //         window.localStorage.setItem(ACCESS_TOKEN, data?.token)               
        //     }
        // }


        if (event === AUTH_EVENTS.logout) {

            if (status === "loading") {
                toast.info("Please wait loading", {
                    autoClose: 1000,
                })
            }

            if (status === "error") {
                toast.dismiss();
                toast.error(message, {
                    isLoading: false,
                    autoClose: 2000
                })
            }

            if (status === "success") {
                toast.dismiss();
                toast.success(message, {
                    isLoading: false,
                    autoClose: 2000
                })

                // * Set Cookiue on login and on logout removeCookie
                setTimeout(() => {
                    window.localStorage.removeItem(ACCESS_TOKEN)
                    setStoredValue(null)
                    dispatch(getCurrentUser());
                }, 500);
            }
        }
    }, [status])

    return (
        <AuthContext.Provider value={[user, setUser]}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;